.u-center-text {
    text-align: center;
}

.u-margin-bottom-small {
    margin-bottom: 1.5rem;
}
.u-margin-bottom-medium {
    margin-bottom: 4rem;
}
.u-margin-bottom-big {
    margin-bottom: 8rem;
}
.u-margin-top-big {
    margin-top: 8 rem;
}

.pointer {
    cursor: pointer;
}

.backgroundgreen {
    padding-left: 0.5rem;
    background: var(--accent2Shade1);
    border-radius: var(--borderRadius);
}

.padding {
    padding: 1rem;
}

.messagewidth {
    width: 95%;
}

.tuto {
    position : relative;
    display: flex;
    align-items: center;
    line-height: 2rem;
    justify-content: space-evenly;
    border-radius: var(--borderRadius);
    border: 1px solid var(--grayColor);
    box-shadow: 0 0.5rem 0.5rem rgba($color-black, 0.5);
    background: var(--accent2Shade1);
    padding: 2rem;
    margin: 2rem;
    font-size: 1.6rem;

    &--fromto {
        justify-content: space-between;
    }

    &__image {
        width: 70%;
        @include respond(tab-land) {
            width: 35%;
        }
    }

    &__help {
        border-radius: var(--borderRadius);
        border: 1px solid var(--grayColor);
        position : absolute;
        padding: 0.3rem;
        top : -5px;
        left : 0px;
        transform: rotate(-30deg);
        background: var(--backgroundColor);
    }

    &__enjoy {
        border-radius: var(--borderRadius);
        border: 1px solid var(--grayColor);
        position : absolute;
        padding: 0.3rem;
        top : 0px;
        left : -80px;
        transform: rotate(-30deg);
        background: var(--backgroundColor);
    }

}

.flexis {
    margin-left: 1rem;
    display : flex;
    align-items: center;
    justify-content: flex-start;
}