.translates {
    margin: 0.5rem;
    

    background-color: var(--backgroundColor);
    border-radius: var(--borderRadius);
    border: 2px solid var(--primaryShade5);

    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;

    @include respond(tab-land) {
        width: 100%;
    }

    &__menu {
        background-color: var(--primaryShade5);
        padding: 0.8rem;
        font-size: 1.3rem;
        letter-spacing: 0.1rem;

        display: flex;
        align-items: center;

        color: var(--backgroundColor);

        & span {
            margin-left: 1rem;
        }

        & button:last-of-type {
            margin-left: 1rem;
        }

        & button:nth-child(3) {
            margin-left: auto;
        }
    }

    &__container {
        max-height: 80vh;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        overflow-y: auto;  

        @include respond(tab-wideport) {
            max-height: 75vh;
        }

        @include respond(tab-land) {
            max-height: 70vh;
        }
    
    }

    &__list {
        list-style: none;        

        @include respond(tab-wideport) {
            width: 100%;
        }
    }

    &__item {
        margin: 1rem;
    }
}

.translate {
    border-radius: 3px;

    display: grid;
    grid-template-columns: 1fr 10fr;
    justify-content: center;
    
    &__radio {
    
        transform: translateY(25%);
    
        &--input {
            display: none;
            &:checked + label:after {
                transform: scale(1);
            }
        }
        &--label {
            position: relative;
            cursor: pointer;
    
            &:before {
                content: "";
                position: absolute;
                width: 2.4rem;
                height: 2.4rem;
                border: 0.1rem solid var(--grayColor);
                border-radius: 50%;
                left: 0;
                top: 0;
            }
            &:after {
                content: "";
                position: absolute;
                width: 1.6rem;
                height: 1.6rem;
                top: 0.4rem;
                left: 0.4rem;
                background: var(--accent3Color);
                border-radius: 50%;
                transform: scale(0);
                transition: 0.3s ease;
            }
        }
    }

    &__form {
        display: grid;
        grid-template-columns: 3fr 6fr;
        justify-content: flex-start;
        align-items: center;
        background-color: var(--primaryShade5);
        box-shadow: 0 0.2rem 0.2rem rgba($color-black, 0.5);


        &__name {
            font-family: inherit;
            font-size: 1.6rem;
            color: var(--backgroundColor);
            text-align: right;
            padding-right: 0.5rem;
        }

        &__input {
            font-size: 1.6rem;
            padding: 1rem;

            width: 100%;

            font-family: inherit;
            flex: 1;
            background-color: var(--backgroundColor);
            color: var(--color-grey-dark-1);

            border: 2px solid var(--primaryShade5);

            &:focus {
                outline: none;
            }

            &:focus:invalid {
                border-bottom: 1px solid var(--backgroundColor);
            }

            &::-webkit-input-placeholder {
                color: var(--backgroundColor);
            }
        }
    }


    &__formText {
        display: grid;
        grid-template-columns: 1fr;
        background-color: var(--primaryShade5);

        &__name {
            font-family: inherit;
            font-size: 1.6rem;
            color: var(--backgroundColor);
            
            padding: 1rem;
        }

        &__input {
            font-size: 1.6rem;
            padding: 0.5rem;
            width: 100%;
            font-family: inherit;
            flex: 1;
            background-color: var(--backgroundColor);
            color: var(--color-grey-dark-1);
            border: 2px solid var(--primaryShade5);
            min-height: 12rem;

            &:focus {
                outline: none;
            }

            &:focus:invalid {
                border-bottom: 1px solid var(--backgroundColor);
            }

            &::-webkit-input-placeholder {
                color: var(--backgroundColor);
            }
        }
    }
}

.imageMot {
    margin-top: 5rem;
    margin-bottom: 5rem;
    align-self: center;
    width: 30%;
    border-radius: 3%;
}