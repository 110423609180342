.fromtos {
 
    margin: 0.5rem;
    
    background-color: var(--backgroundColor);
    border-radius: var(--borderRadius);
    border: 2px solid var(--primaryShade5);
    
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    
    @include respond(tab-land) {
        width: 100%;
        order : 1;
    }

    &__menu {
        background-color: var(--primaryShade5);
        padding: 0.8rem;
        font-size: 1.4rem;
        letter-spacing: 0.1rem;

        display: flex;
        align-items: center;

        color : var(--backgroundColor);

        & span:last-of-type {
            margin-left: 1rem;
        }
        & button:last-child {
            margin-left: auto;
        }

        & button:focus {
            top: -3px;
        }
    }


    &__list{
        width: 100%;
        max-height: 70vh;
        overflow-y: scroll;
        list-style: none;   
        
        &--visible {
            display: none;
        } 
    }

    &__item{
        margin-left: 2rem;
    }    

    &__container{
        display: flex;
        align-items: stretch;
        justify-content: center;    
    }

}

.fromto {

    display: grid;
    grid-template-columns: 1fr 6fr 1fr 6fr;

    &__radio {
    
        transform: translateY(25%);
    
        &--input {
            display: none;
            &:checked + label:after {
                transform: scale(1);
            }
        }
        &--label {
            position: relative;
            cursor: pointer;
    
            &:before {
                content: "";
                position: absolute;
                width: 2.4rem;
                height: 2.4rem;
                border: 0.1rem solid var(--grayColor);
                border-radius: 50%;
                left: 0;
                top: 0;
            }
            &:after {
                content: "";
                position: absolute;
                width: 1.6rem;
                height: 1.6rem;
                top: 0.4rem;
                left: 0.4rem;
                background: var(--secondaryColor);
                border-radius: 50%;
                transform: scale(0);
                transition: 0.3s ease;
            }
        }
    }

    &__form {
        margin-left: 0.8rem;
        padding: 1.5rem;       
        &__name {
            font-size: 1.6rem;
            color: var(--foregroundColor);
        }    
    }
}
