.lecons {
    margin: 0.5rem;

    background-color: var(--backgroundColor);
    border-radius: var(--borderRadius);
    border: 2px solid var(--primaryShade5);

    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;

    @include respond(tab-land) {
        width: 100%;
    }

    &__menu {
        background-color: var(--primaryShade5);
        padding: 0.8rem;
        font-size: 1.6rem;
        letter-spacing: 0.1rem;

        display: flex;
        align-items: center;

        color: var(--backgroundColor);

        & span:last-of-type {
            margin-left: 1rem;
        }
        & button:last-child {
            margin-left: auto;
        }

        & button:focus {
            top: -3px;
        }
    }

    &__list--container {
        max-height: 65vh;
        overflow-y: auto;
    }

    &__list {
        padding-top: 0.5rem;
        list-style: none;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        &--visible {
            display: none;
        }
    }

    &__item {
        margin: 0.5rem;
    }

    &__search {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 0.5rem;

        &__input {
            flex: 1;
            margin: 0.5rem;
            font-size: 1.6rem;
            padding: 0.5rem;

            font-family: inherit;

            background-color: var(--backgroundColor);
            color: var(--color-grey-dark-1);
            border: 2px solid var(--primaryShade5);
            border-radius: var(--borderRadius);

            &:focus {
                outline: none;
                border: 2px solid var(--accent2Color);
            }

            &:focus:invalid {
                border-bottom: 1px solid var(--color-white);
            }

            &::-webkit-input-placeholder {
                color: var(--color-white);
            }

            &:read-only {
                color: var(--neutralShade6);
            }
        }
    }
}

.lecon {
    background: var(--backgroundColor);

    display: flex; /* Children use Flexbox */
    flex-direction: column; /* Rotate Axis */

    border-radius: var(--borderRadius);
    border: 2px solid var(--primaryShade5);
    box-shadow: 0 0.3rem 0.2rem rgba($color-black, 0.5);


    color: var(--foregroundColor);

    -webkit-animation: fadein 0.5s linear forwards;
    animation: fadein 0.5s linear forwards;
    z-index: 1000;

    &__open {
        z-index: 1005;
    }

    &__green {
        border: 2px solid var(--accentShade4);
    }

    &__red {
        border: 2px solid var(--accent3Shade4);
    }

    &__header {
        padding: 0.5rem;
        font-size: 1.6rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__main {
        border-top: 1px solid var(--neutralShade7);
        padding: 0.5rem;
        font-size: 1.2rem;
        grid-template-areas:
            "select mot"
            "date date";
        display: grid;

        align-items: center;

        &__word {
            grid-area: mot;
            text-align: right;
        }

        &__create {
            grid-area: date;
            text-align: right;
        }
    }

    &__footer {
        padding-top: 0.4rem;
        background: var(--primaryShade5);
        text-align: center;
        display: flex;
        justify-content: space-around;

        &__green {
            background: var(--accentShade4);
        }

        &__red {
            background: var(--accent3Shade4);
        }
    }
}

.lecon-fadeout {
    background: var(--backgroundColor);
    display: flex; /* Children use Flexbox */
    flex-direction: column; /* Rotate Axis */

    border-radius: var(--borderRadius);
    border: 2px solid var(--primaryShade5);

    color: var(--foregroundColor);

    -webkit-animation: fadeout 0.5s linear forwards;
    animation: fadeout 0.5s linear forwards;

    &__header {
        padding: 0.5rem;
        font-size: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__main {
        border-top: 1px solid var(--primaryShade5);
        padding: 0.5rem;
        font-size: 1.2rem;
        grid-template-areas:
            "select mot"
            "date date";
        display: grid;

        align-items: center;

        &__word {
            grid-area: mot;
            text-align: right;
        }

        &__create {
            grid-area: date;
            text-align: right;
        }
    }

    &__footer {
        padding-top: 0.4rem;
        background: var(--primaryShade5);
        text-align: center;
        display: flex;
        justify-content: space-around;
    }
}

.checkbox {
    position: relative;
    user-select: none;
    margin-bottom: 10px;
    grid-area: select;
    input {
        display: none;
    }
    label {
        position: relative;
        vertical-align: middle;
        cursor: pointer;
        font-weight: 500;
        padding-left: 35px;
    }
    span.box {
        display: inline-block;
        width: 20px;
        border-radius: var(--borderRadius);
        border: 1px solid var(--grayColor);
        width: 24px;
        height: 24px;
        vertical-align: middle;
        margin-right: 3px;
        transition: 0.3s ease;
        position: absolute;
        left: 0;
        &:before,
        &:after {
            content: "";
            position: absolute;
            width: 4px;
            height: 16px;
            border-radius: 40px;
            background: var(--backgroundColor);
            transition: all 0.3s ease;
        }
        &:before {
            transform: rotate(45deg) translateY(-5px) translateX(10px) scale(0);
        }
        &:after {
            height: 8px;
            transform: rotate(-45deg) translateY(10px) translateX(-4px) scale(0);
        }
    }
    input:checked {
        + label {
            span.box {
                background: var(--accent2Color);
                border-color: var(--accent2Color);
                &:before {
                    transform: rotate(45deg) translateY(-5px) translateX(10px) scale(1);
                }
                &:after {
                    height: 8px;
                    transform: rotate(-45deg) translateY(10px) translateX(-4px) scale(1);
                }
            }
        }
    }
}

.checkboxnochange {
    position: relative;
    user-select: none;
    margin-bottom: 10px;
    grid-area: select;
    input {
        display: none;
    }
    label {
        position: relative;
        vertical-align: middle;
        cursor: pointer;
        font-weight: 500;
        padding-left: 35px;
    }
    span.box {
        display: inline-block;
        width: 20px;
        border-radius: var(--borderRadius);
        border: 1px solid var(--grayColor);
        width: 24px;
        height: 24px;
        vertical-align: middle;
        margin-right: 3px;
        transition: 0.3s ease;
        position: absolute;
        left: 0;
        &:before,
        &:after {
            content: "";
            position: absolute;
            width: 4px;
            height: 16px;
            border-radius: 40px;
            background: var(--backgroundColor);
            transition: all 0.3s ease;
        }
        &:before {
            transform: rotate(45deg) translateY(-5px) translateX(10px) scale(0);
        }
        &:after {
            height: 8px;
            transform: rotate(-45deg) translateY(10px) translateX(-4px) scale(0);
        }
    }
}
