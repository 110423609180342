@keyframes moveInLeft {
    0% {
        opacity: 0;
        transform: translateX(-10rem);
    }

    80% {
        transform: translateX(1rem);
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}

@keyframes moveInRight {
    0% {
        opacity: 0;
        transform: translateX(10rem);
    }

    80% {
        transform: translateX(-1rem);
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}

@keyframes moveInBottom {
    0% {
        opacity: 0;
        transform: translateY(3rem);
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}

@keyframes scale-in {
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes expand {
    50% {
        border-radius: 6px;
    }
    100% {
        border-radius: 4px;
        box-shadow: var(--shadow-dark);
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0.95;
    }
}

@-webkit-keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeout {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fadeout {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes mymovein {
    from {
        fill: var(--backgroundColor);
    }
    to {
        fill: var(--accent3Shade4);
    }
}

@keyframes mymoveout {
    from {
        fill: var(--accent3Shade4);
    }
    to {
        fill: var(--backgroundColor);
    }
}
