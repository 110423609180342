.popup__print {
    position: fixed;
    background: #ffffff30;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 3000;
    display: flex;
    justify-content: center;
    align-items: center;

    &__form {
        border-radius: var(--borderRadius);
        border: 2px solid var(--neutralShade7);
        background: var(--neutralShade2);
        animation: scale-in 0.2s ease-out forwards, expand 0.2s ease-out forwards;
        transform: scale(0);
    }

    &__container{
        background: var(--neutralShade2);
        display: flex;
        padding: 2rem;
        align-items: center;
        
        &--button {
            display: grid;
            grid-template-columns:  1fr 1fr;
    
            margin-top: 1rem;
            background: var(--backgroundColor);    
        }
    }

    &__label {
        font-size: 1.7rem;
        border: none;
        width: 100%;
        text-align: center;

    }

    &__button{
        outline: none;
        text-align: center;
        display: inline-block;
        border: none;
        margin: 2rem;
        font-size: 1.6rem;
        font-weight: bold;
        padding: 1rem;
        cursor: pointer;
        border-radius: var(--borderRadius);
        background: var(--neutralShade2);
	    color: var(--neutralShade7);
        position: relative;
        top: 0;
        transition: 0.2s ease;
        &:hover,
        &.hover {
            top: -3px;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
        }
    }
}