.release {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin: 0.5rem;
    background-color: var(--primaryShade5);
    border-radius: var(--borderRadius);
    border: 2px solid var(--primaryShade5);
    color: var(--neutralShade7);

    @include respond(tab-land) {
        max-height: 78vh;
        overflow-y: auto;
    }

    &__container {
        padding: 2rem;
        background-color: var(--backgroundColor);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;

        @include respond(tab-wideport) {
            width: 90%;
        }

        @include respond(tab-land) {
            width: 80%;
        }

        @include respond(tab-large) {
            width: 70%;
        }
    }

    & a {
        text-decoration: none;

        &:link,
        &:visited {
            color: var(--neutralShade7);
        }

        &:link:active,
        &:visited:active {
            color: var(--neutralShade7);
        }
    }

    &__index {
        padding: 2rem;
    }

    &__smartphone {
        width: 100%;
        display: flex;
        justify-content: space-evenly;

        &__item {
            width: 20%;
            margin: 1rem;
        }
    }

    &__short {
        margin-top: 1rem;
        display: flex;
        align-items: center;
    }

    &__section {
        margin-top: 1rem;
    }

    & h1 {
        font-size: 1.4rem;

        @include respond(tab-port) {
            font-size: 1.6rem;
        }

        @include respond(tab-wideport) {
            font-size: 1.8rem;
        }

        @include respond(tab-land) {
            font-size: 2rem;
        }
    }

    & p {
        margin-top: 2rem;
        font-size: 1.6rem;
        line-height: 2.5rem;
        align-self: flex-start;

        @include respond(tab-wideport) {
            font-size: 1.7rem;
            margin-right: 5rem;
            margin-left: 5rem;
        }

        @include respond(tab-land) {
            font-size: 1.8rem;
            margin-right: 5rem;
            margin-left: 5rem;
        }

        @include respond(tab-large) {
            font-size: 1.9rem;
            margin-right: 5rem;
            margin-left: 5rem;
        }
    }

    &__feature {
        margin-bottom: 2rem;
        width: 80%;
        padding: 3rem;
        border-radius: var(--borderRadius);
        box-shadow: 0 0.5rem 1.5rem rgba($color-black, 0.5);

        &__item {
            font-size: 1.5rem;
            font-weight: bold;

            &:not(:last-child) {
                margin-bottom: 1rem;
            }

            @include respond(tab-wideport) {
                font-size: 1.6rem;
            }

            @include respond(tab-land) {
                font-size: 1.7rem;
            }

            @include respond(tab-large) {
                font-size: 1.8rem;
            }
        }
    }

}
