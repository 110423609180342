.flashcards {
    margin: 0.5rem;
    background-color: var(--backgroundColor);
    border-radius: var(--borderRadius);
    border: 2px solid var(--primaryShade5);

    //box-shadow: var(--shadow-dark);
    //overflow-y: scroll;

    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;

    font-size: 1.8rem;

    @include respond(tab-land) {
        width: 100%;
    }

    &__menu {
        background-color: var(--primaryShade5);
        padding: 0.9rem;
        display: flex;
        align-items: center;
        color: var(--backgroundColor);
        justify-content: space-around;
        list-style: none;
    }
}

.flashcardself {
    min-height: 30rem;

    flex: 1;

    display: flex;
    flex-direction: column;

    justify-content: space-between;

    &__info {
        display: flex;
        justify-content: space-evenly;
        margin: 0.5rem;
    }

    &__menu {
        background-color: var(--primaryShade5);
        padding: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        color: var(--backgroundColor);
    }

    &__guess {
        overflow-wrap: break-word; 
        line-height: 2.5rem;
        margin-left: 2rem;
        margin-right: 2rem;
        align-self: stretch;
        text-align: center;
        font-size: 2.5rem;
        background-color: var(--primaryShade5);
        padding: 1rem;
        color: var(--backgroundColor);
        -webkit-animation: fadein 0.5s linear forwards;
        animation: fadein 0.5s linear forwards;
        border-radius: var(--borderRadius);
        border: 2px solid var(--primaryShade5);
        box-shadow: 0 0.2rem 0.2rem rgba($color-black, 0.5);
        
        @include respond(tab-land) {
            padding: 2rem;
        }
    }

    &__answer {
        overflow-wrap: break-word; 
        line-height: 2.5rem;
        margin-left: 2rem;
        margin-right: 2rem;
        margin-bottom: 2rem;
        align-self: stretch;
        text-align: center;
        font-size: 2.5rem;
        padding: 1rem;
        background-color: var(--accent2Color);
        color: var(--backgroundColor);
        -webkit-animation: fadein 0.5s linear forwards;
        animation: fadein 0.5s linear forwards;
        border-radius: var(--borderRadius);
        border: 2px solid var(--accent2Color);
        box-shadow: 0 0.2rem 0.2rem rgba($color-black, 0.5);


        @include respond(tab-land) {
            padding: 2rem;
        }
    }
}

.flashcardmulti {
    min-height: 50rem;

    flex: 1;

    display: flex;
    flex-direction: column;

    justify-content: space-between;

    &__info {
        display: flex;
        justify-content: space-evenly;
        margin: 0.5rem;
    }

    &__menu {
        background-color: var(--primaryShade5);
        padding: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        color: var(--backgroundColor);
    }

    &__answer {
        list-style-type: none;
    }

    &__guess {
        overflow-wrap: break-word; 
        line-height: 2.5rem;
        margin-left: 2rem;
        margin-right: 2rem;
        align-self: stretch;
        text-align: center;
        font-size: 2.5rem;
        background-color: var(--primaryShade5);
        padding: 0.8rem;
        color: var(--backgroundColor);
        -webkit-animation: fadein 0.5s linear forwards;
        animation: fadein 0.5s linear forwards;
        border-radius: var(--borderRadius);
        border: 2px solid var(--primaryShade5);
        box-shadow: 0 0.2rem 0.2rem rgba($color-black, 0.5);

        @include respond(tab-land) {
            padding: 2rem;
        }
    }

    &__proposal {
        overflow-wrap: break-word; 
        line-height: 2.5rem;
        font-size: 2.5rem;
        margin-left: 2rem;
        margin-right: 2rem;
        align-self: stretch;
        background-color: var(--primaryShade5);
        margin-bottom: 1rem;
        padding: 0.8rem ;
        color: var(--backgroundColor);
        text-align: center;
        -webkit-animation: fadein 0.5s linear forwards;
        animation: fadein 0.5s linear forwards;
        border-radius: var(--borderRadius);
        border: 2px solid var(--primaryShade5);
        box-shadow: 0 0.2rem 0.2rem rgba($color-black, 0.5);

        @include respond(tab-land) {
            padding: 2rem;
        }
    }

    &__choice {
        //        padding-bottom: 1.5rem;
    }

    &__solution {
        overflow-wrap: break-word; 
        line-height: 2.5rem;
        font-size: 2.5rem;
        margin-left: 2rem;
        margin-right: 2rem;
        align-self: stretch;
        background-color: var(--accent2Color);
        padding: 0.8rem;
        color: var(--backgroundColor);
        text-align: center;
        margin-bottom: 1rem;
        -webkit-animation: fadein 0.5s linear forwards;
        animation: fadein 0.5s linear forwards;
        border-radius: var(--borderRadius);
        border: 2px solid var(--accent2Color);
        box-shadow: 0 0.2rem 0.2rem rgba($color-black, 0.5);

        @include respond(tab-land) {
            padding: 2rem;
        }
    }

    &__wrong {
        overflow-wrap: break-word; 
        line-height: 2.5rem;
        font-size: 2.5rem;
        margin-left: 2rem;
        margin-right: 2rem;
        align-self: stretch;
        background-color: var(--accent3Color);
        padding: 0.8rem;
        color: var(--backgroundColor);
        text-align: center;
        margin-bottom: 1rem;
        -webkit-animation: fadein 0.5s linear forwards;
        animation: fadein 0.5s linear forwards;
        border-radius: var(--borderRadius);
        border: 2px solid var(--accent3Color);
        box-shadow: 0 0.2rem 0.2rem rgba($color-black, 0.5);

        @include respond(tab-land) {
            padding: 2rem;
        }
    }
}

.flashcardwrite {
    width: 100%;
    min-height: 20rem;

    flex: 1;

    display: flex;
    flex-direction: column;

    justify-content: space-between;

    &__info {
        display: flex;
        justify-content: space-evenly;
        margin: 0.5rem;
    }

    &__menu {
        background-color: var(--primaryShade5);
        padding: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        color: var(--backgroundColor);
    }

    &__guess {
        overflow-wrap: break-word; 
        line-height: 2.5rem;
        margin-left: 2rem;
        margin-right: 2rem;
        align-self: stretch;
        text-align: center;
        font-size: 2.5rem;
        background-color: var(--primaryShade5);
        padding: 1rem;
        color: var(--backgroundColor);
        -webkit-animation: fadein 0.5s linear forwards;
        animation: fadein 0.5s linear forwards;
        border-radius: var(--borderRadius);
        border: 2px solid var(--primaryShade5);
        box-shadow: 0 0.2rem 0.2rem rgba($color-black, 0.5);

        @include respond(tab-land) {
            padding: 2rem;
        }
    }

    &__answer {
        overflow-wrap: break-word; 
        line-height: 2.5rem;
        margin-left: 2rem;
        margin-right: 2rem;
        margin-bottom: 2rem;
        align-self: stretch;
        text-align: center;
        font-size: 2.5rem;
        background-color: var(--accent3Color);
        padding: 1rem;
        color: var(--backgroundColor);
        margin-bottom: 1rem;
        -webkit-animation: fadein 0.5s linear forwards;
        animation: fadein 0.5s linear forwards;
        border-radius: var(--borderRadius);
        border: 2px solid var(--accent3Color);
        box-shadow: 0 0.2rem 0.2rem rgba($color-black, 0.5);

        @include respond(tab-land) {
            padding: 2rem;
        }
    }

    &__answer--green {
        overflow-wrap: break-word; 
        line-height: 2.5rem;
        margin-left: 2rem;
        margin-right: 2rem;
        margin-bottom: 2rem;
        align-self: stretch;
        text-align: center;
        font-size: 2.5rem;
        background-color: var(--accent2Color);
        padding: 1rem;
        color: var(--backgroundColor);
        margin-bottom: 1rem;
        -webkit-animation: fadein 0.5s linear forwards;
        animation: fadein 0.5s linear forwards;
        border-radius: var(--borderRadius);
        border: 2px solid var(--accent2Color);
        box-shadow: 0 0.2rem 0.2rem rgba($color-black, 0.5);

        @include respond(tab-land) {
            padding: 2rem;
        }
    }

    &__input {
        line-height: 2.5rem;
        width: 90%;
        margin: 2rem;
        font-size: 2.5rem;
        padding: 1rem;

        font-family: inherit;

        background-color: var(--backgroundColor);
        color: var(--color-grey-dark-1);
        border: 2px solid var(--primaryShade5);
        box-shadow: 0 0.2rem 0.2rem rgba($color-black, 0.5);

        &:focus {
            outline: none;
            border: 2px solid var(--accent2Color);
        }

        &:focus:invalid {
            border-bottom: 1px solid var(--color-white);
        }

        &::-webkit-input-placeholder {
            color: var(--color-white);
        }

        &:read-only {
            color: var(--neutralShade6);
        }
    }
}

.flashcardtexte {
    flex: 1;

    display: flex;
    flex-direction: column;

    justify-content: space-between;
    overflow: auto;

    &__info {
        display: flex;
        justify-content: space-evenly;
        margin: 0.5rem;
    }

    &__menu {
        background-color: var(--primaryShade5);
        padding: 0.8rem;
        font-size: 1.9rem;
        letter-spacing: 0.1rem;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        color: var(--backgroundColor);
        margin-bottom: 1rem;
    }

    &__guess {
        overflow-wrap: break-word; 
        margin: 2rem;
        align-self: stretch;
        font-size: 2rem;
        background-color: var(--primaryShade5);
        padding: 1.5rem 3rem;
        line-height: 2.5rem;
        color: var(--backgroundColor);
        margin-bottom: 1rem;
        -webkit-animation: fadein 0.5s linear forwards;
        animation: fadein 0.5s linear forwards;
        border-radius: var(--borderRadius);
        border: 2px solid var(--primaryShade5);
        box-shadow: 0 0.2rem 0.2rem rgba($color-black, 0.5);

    }

    &__answer {
        overflow-wrap: break-word; 
        margin-left: 2rem;
        margin-right: 2rem;
        margin-bottom: 2rem;
        align-self: stretch;
        font-size: 2rem;
        padding: 1.5rem 3rem;
        line-height: 2.5rem;
        background-color: var(--accent2Color);
        color: var(--backgroundColor);
        -webkit-animation: fadein 0.5s linear forwards;
        animation: fadein 0.5s linear forwards;
        border-radius: var(--borderRadius);
        border: 2px solid var(--accent2Color);
        box-shadow: 0 0.2rem 0.2rem rgba($color-black, 0.5);

    }
}

.flashcardmultitexte {
    min-height: 50rem;

    flex: 1;

    display: flex;
    flex-direction: column;

    justify-content: space-between;

    &__info {
        display: flex;
        justify-content: space-evenly;
        margin: 0.5rem;
    }

    &__menu {
        background-color: var(--primaryShade5);
        padding: 0.8rem;
        font-size: 1.9rem;
        letter-spacing: 0.1rem;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        color: var(--backgroundColor);
    }

    &__answer {
        list-style-type: none;
    }

    &__guess {
        overflow-wrap: break-word; 
        margin: 2rem;
        align-self: stretch;
        font-size: 2rem;
        background-color: var(--primaryShade5);
        padding: 1.5rem 3rem;
        line-height: 2.5rem;
        color: var(--backgroundColor);
        margin-bottom: 3rem;
        -webkit-animation: fadein 0.5s linear forwards;
        animation: fadein 0.5s linear forwards;
        border-radius: var(--borderRadius);
        border: 2px solid var(--primaryShade5);
        box-shadow: 0 0.2rem 0.2rem rgba($color-black, 0.5);

    }

    &__proposal {
        overflow-wrap: break-word; 
        margin-left: 2rem;
        margin-right: 2rem;
        align-self: stretch;
        background-color: var(--primaryShade5);
        margin-bottom: 1rem;
        padding: 1.5rem 3rem;
        line-height: 2.5rem;
        color: var(--backgroundColor);
        margin-bottom: 1rem;
        -webkit-animation: fadein 0.5s linear forwards;
        animation: fadein 0.5s linear forwards;
        border-radius: var(--borderRadius);
        border: 2px solid var(--primaryShade5);
        box-shadow: 0 0.2rem 0.2rem rgba($color-black, 0.5);

    }

    &__choice {
        //        padding-bottom: 1.5rem;
    }

    &__solution {
        overflow-wrap: break-word; 
        margin-left: 2rem;
        margin-right: 2rem;
        align-self: stretch;
        background-color: var(--accent2Color);
        padding: 1.5rem 3rem;
        line-height: 2.5rem;
        color: var(--backgroundColor);
        margin-bottom: 1rem;
        -webkit-animation: fadein 0.5s linear forwards;
        animation: fadein 0.5s linear forwards;
        border-radius: var(--borderRadius);
        border: 2px solid var(--accent2Color);
        box-shadow: 0 0.2rem 0.2rem rgba($color-black, 0.5);

    }

    &__wrong {
        overflow-wrap: break-word; 
        margin-left: 2rem;
        margin-right: 2rem;
        align-self: stretch;
        background-color: var(--accent3Color);
        padding: 1.5rem 3rem;
        line-height: 2.5rem;
        color: var(--backgroundColor);
        margin-bottom: 1rem;
        -webkit-animation: fadein 0.5s linear forwards;
        animation: fadein 0.5s linear forwards;
        border-radius: var(--borderRadius);
        border: 2px solid var(--accent3Color);
        box-shadow: 0 0.2rem 0.2rem rgba($color-black, 0.5);

    }
}

.flashcardmultilightbuld {
    position:relative;
    align-self: center;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.fromCursor {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
    padding: 0.5rem;
    margin-right: 1rem;
    border-radius: 40%;
    border: 1px solid var(--accent2Color);
    background-color: var(--accent2Color);
    cursor: pointer;
}

.toCursor {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
    padding: 0.5rem;
    background-color: var(--primaryShade5);
    cursor: pointer;
}

.lightbulb {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
