.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;

    background-color: var(--primaryShade1);
}

.header {
    background-color: var(--primaryShade1);
    width: 100%;
    position: fixed;
    z-index: 90;

    @include respond(tab-land) {
        justify-content: space-between;
        flex-direction: row;
        flex: 1;
    }

}

.main-3 {
    margin-top: 11rem;
    display: flex;
    flex-direction: column;

    @include respond(tab-land) {
        justify-content: space-between;
        flex-direction: row;
        flex: 1;
    }
}

.main-1 {
    margin-top: 11rem;
    margin-left: 1rem;
    margin-right: 1rem;
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
}

.footer {
    background: radial-gradient(circle, rgba(2, 119, 189, 1) 78%, rgba(2, 136, 209, 1) 100%);
    border-radius: 3px;
    box-shadow: var(--shadow-light);
    margin: 1rem;
    padding: 1rem;
    text-align: center;
    display: flex;
    justify-content: center;

    &__item {
        margin-left: 1.4rem;
        margin-right: 1.4rem;
        cursor: pointer;

        @include respond(tab-port) {
            margin-left: 3rem;
            margin-right: 3rem;
        }

        @include respond(tab-wideport) {
            margin-left: 3rem;
            margin-right: 3rem;
        }

        @include respond(tab-land) {
            margin-left: 4rem;
            margin-right: 4rem;
        }
    }
}

//test
.card {
    padding: 1em;
    border: 1px solid rgba(0, 0, 0, 0.25);
}
