.doc {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;    
    margin: 0.5rem;
    background-color: var(--primaryShade5);
    border-radius: var(--borderRadius);
    border: 2px solid var(--primaryShade5);
    color: var(--neutralShade7);   

    @include respond(tab-land) {
        max-height: 78vh;
        overflow-y: auto;
    }

    &__container {
        padding: 1rem;
        background-color: var(--backgroundColor);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        width: 100%;

        @include respond(tab-wideport) {
            width: 90%;
        }

        @include respond(tab-land) {
            width: 80%;
        }

        @include respond(tab-large) {
            width: 70%;
        }
    }

    & a{
        text-decoration: none;

        &:link,  &:visited {
            color: var(--neutralShade7);
        }

        &:link:active,  &:visited:active {
            color: var(--neutralShade7);
        }
    }

    &__index {
        width: 100%;
        padding: 2rem;
    }

    &__short {
        margin-top: 1rem;
        display: flex;
        align-items: center;
    }

    &__section {
        width: 100%;
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
    }

    & h1 {
        margin-top: 1.5rem;
        font-size: 1.7rem;

        @include respond(tab-land) {
            font-size: 2.5rem;
            margin-right: 5rem;
            margin-left: 5rem;
        }
    }

    & h2 {
        font-size: 1.6rem;
        margin-left: 0.5rem;

        @include respond(tab-port) {
            font-size: 2rem;
        }
    }

    & p {
        margin-top: 2rem;
        font-size: 1.6rem;
        line-height: 2.5rem;
        align-self: flex-start;

        @include respond(tab-wideport) {
            font-size: 1.7rem;
            margin-right: 5rem;
            margin-left: 5rem;
        }

        @include respond(tab-land) {
            font-size: 1.8rem;
            margin-right: 5rem;
            margin-left: 5rem;
        }

        @include respond(tab-large) {
            font-size: 1.9rem;
            margin-right: 5rem;
            margin-left: 5rem;
        }
    }

    &__bg-video {
        width: 80%;
        height: 348px;
        align-self: center;

        @include respond(tab-wideport) {
            width: 70%;
        }

        @include respond(tab-land) {
            width: 55%;
        }

        @include respond(tab-large) {
            width: 40%;
        }
    }

    &__image {
        width: 90%;
        align-self: center;

        @include respond(tab-wideport) {
            width: 80%;
        }

        @include respond(tab-land) {
            width: 65%;
        }

        @include respond(tab-large) {
            width: 55%;
        }
    }

    &__image2 {
        width: 95%;
        align-self: center;
    }

    &__image3 {
        width: 95%;
        align-self: center;

        @include respond(tab-large) {
            width: 70%;
        }

    }

    &__list {
        align-self: center;
        font-size: 1.6rem;
    }

}
