
//COLORS
$color-primary: #0288d1;
$color-primary-light: #7ed56f;
$color-primary-dark: #28b485;

$color-secondary-light: #ffb900;
$color-secondary-dark: #ff7730;

$color-tertiary-light: #2998ff;
$color-tertiary-dark: #5643fa;

$color-grey-light-1: #f7f7f7;
$color-grey-light-2: #eee;

$color-grey-dark: #777;
$color-grey-dark-2: #999;
$color-grey-dark-3: #333;

$color-white: #fff;
$color-black: #000;

$default-font-size : 1.6rem;

//GRID
//ICON
/* Rules for sizing the icon. */
.material-icons.md-18 { font-size: 18px; }
.material-icons.md-24 { font-size: 24px; }
.material-icons.md-36 { font-size: 36px; }
.material-icons.md-48 { font-size: 48px; }

/* Rules for using icons as black on a light background. */
.material-icons.md-dark { color: rgba(0, 0, 0, 0.9); }
.material-icons.md-dark.md-inactive { color: rgba(0, 0, 0, 0.26); }

/* Rules for using icons as white on a dark background. */
.material-icons.md-light { color: rgba(255, 255, 255, 1); }
.material-icons.md-light.md-inactive { color: rgba(255, 255, 255, 0.3); }

.material-icons.orange600 { color: #FB8C00; }






.alert {
	margin-bottom: 15px;
	display: block;
	padding: 10px 15px;
	border-radius: var(--borderRadius);
	font-weight: 500;
	position: relative;
	cursor: pointer;
	&:before,
	&:after {
		content: "";
		position: absolute;
		width: 4px;
		height: 16px;
		border-radius: 40px;
		right: 30px;
		top: 8px;
	}
	&:before {
		transform: rotate(45deg) translateY(-5.5px) translateX(13.5px);
	}
	&:after {
		transform: rotate(-45deg) translateY(13.5px) translateX(5.5px);
	}
}

.status-primary {
	background: var(--primaryShade1);
	color: var(--primaryShade4);
	&:before,
	&:after {
		background: var(--primaryShade4);
	}
}
