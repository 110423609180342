.configs {
    background-color: var(--backgroundColor);
    border-radius: var(--borderRadius);
    border: 2px solid var(--primaryShade5);

    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;

    margin: 0.5rem;

    @include respond(tab-land) {
        width: 100%;
        max-height: 80vh;
        overflow-y: auto;  
    }

    &__mot {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
    }

    &__title {
        background-color: var(--primaryShade5);
        text-align: center;
        font-size: 1.9rem;
        letter-spacing: 0.1rem;
        padding: 1rem;
        color: var(--backgroundColor);
        border-bottom: 1px solid var(--backgroundColor);
    }

    &__menu {
        background-color: var(--primaryShade5);
        padding: 0.8rem;
        font-size: 1.6rem;
        letter-spacing: 0.1rem;

        display: flex;
        align-items: center;

        color: var(--backgroundColor);

        & span {
            margin-left: 1rem;
        }

        & button:last-of-type {
            margin-left: 1rem;
        }

        & button:nth-child(3) {
            margin-left: auto;
        }
    }

    &__list {
        max-height: 60vh;
        overflow-y: auto;
        list-style: none;
    }

    &__item {
        position: relative;
        margin: 1rem;
    }

}

.config {
    background-color: var(--primaryShade5);
    box-shadow: 0 0.2rem 0.2rem rgba($color-black, 0.5);

    border-radius: 3px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 0.3rem;

    -webkit-animation: fadein 0.5s linear forwards;
    animation: fadein 0.5s linear forwards;

    &__input {
        font-size: 1.6rem;
        padding: 1rem;
        width: 100%;
        font-family: inherit;
        flex: 1;
        background-color: var(--backgroundColor);
        color: var(--color-grey-dark-1);
        border: 2px solid var(--primaryShade5);

        &:focus {
            outline: none;
        }

        &:focus:invalid {
            border-bottom: 1px solid var(--color-white);
        }

        &::-webkit-input-placeholder {
            color: var(--color-white);
        }
    }

}

.config-fadeout{
    background-color: var(--primaryShade5);

    border-radius: 3px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 0.3rem;

    -webkit-animation: fadeout 0.5s linear forwards;
    animation: fadeout .5s linear forwards;

    &__input {
        font-size: 1.6rem;
        padding: 1rem;
        width: 100%;
        font-family: inherit;
        flex: 1;
        background-color: var(--backgroundColor);
        color: var(--color-grey-dark-1);
        border: 2px solid var(--primaryShade5);

        &:focus {
            outline: none;
        }

        &:focus:invalid {
            border-bottom: 1px solid var(--color-white);
        }

        &::-webkit-input-placeholder {
            color: var(--color-white);
        }
    }
}


.fileImport {
    margin: 1rem;

    background-color: var(--primaryShade5);

    border-radius: var(--borderRadius);
    border: 2px solid var(--primaryShade5);

    padding: 1rem;
    display: flex;
    justify-content: center;

    & > input[type="file"] {
        display: none;
    }
    & > .custom-file-upload {
        margin-left: 1rem;
        font-size: 1.6rem;
        align-self: center;
        color: var(--backgroundColor);
        cursor: pointer;
    }
}
