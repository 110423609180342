.autoimport {
    margin: 1rem;
    background-color: var(--backgroundColor);
    border-radius: var(--borderRadius);
    border: 2px solid var(--primaryShade5);
    
    &__title {
        background-color: var(--primaryShade5);
        text-align: center;
        font-size: 1.9rem;
        letter-spacing: 0.1rem;
        padding: 1rem;
        color: var(--backgroundColor);
        border-bottom: 1px solid var(--backgroundColor);
    }
    
    &__langue {
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    
    &__kanji {
        border-radius: var(--borderRadius);
        border: 2px solid var(--primaryShade5);    
        margin : 1rem;
        padding: 1rem;
    }

    &__kanjiGrid {
        padding: 1rem;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }

    &__checkbox {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-left: 1rem;
    }

    &__buttonContainer{
        display: flex;
        justify-content: center;
    }

    &__button {
        margin-bottom : 1rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        
        padding : 0.5rem;
        border-radius: var(--borderRadius);
        border: 2px solid var(--primaryShade5);    
        fill: var(--foregroundColor);
        background: var(--primaryShade5);
        font-size: 1.6rem;
        color: var(--backgroundColor);
        
        &,
        &:link,
        &:visited {
            cursor: pointer;
        }
    
        &:active,
        &:focus {
            outline: none;
        }
    
    }
}
