
.bigfont {
    font-size: 4.5rem !important;
    line-height: 4.5rem  !important;
}

.middlefont {
    font-size: 3.5rem !important;
    line-height: 3.5rem  !important;
}

.averagefont {
    font-size: 2.5rem !important;
    line-height: 2.5rem  !important;
}
