.icon-button {
    border: none;
    fill: var(--backgroundColor);
    background: var(--primaryShade5);

    &,
    &:link,
    &:visited {
        cursor: pointer;
    }

    &:active,
    &:focus {
        outline: none;
    }

    &--small {
        border: none;
        background: var(--primaryShade5);
    }

    &--smallGreen {
        border: none;
        background: var(--accentShade4);
    }

    &--smallRed {
        border: none;
        background: var(--accent3Shade4);
    }


    &--green {
        border: none;
        fill: var(--primaryShade5);
        background : var(--backgroundColor);
    }
}

.icon {
    height: 3.7rem;
    width: 3.7rem;
    transition: fill 0.5s ease-in-out;

    &:hover {
        cursor: pointer;
        fill: var(--foregroundColor);
    }

    &--small {
        height: 2rem;
        width: 2rem;
        fill: var(--backgroundColor);
        background: var(--primaryShade5);
        transition: fill 0.5s ease-in-out;

        &:hover {
            cursor: pointer;
            fill: var(--foregroundColor);
        }
    }

    &--smallRed {
        height: 2rem;
        width: 2rem;
        fill: var(--backgroundColor);
        background: var(--accent3Shade4);
        transition: fill 0.5s ease-in-out;

        &:hover {
            cursor: pointer;
            fill: var(--foregroundColor);
        }
    }

    &--smallGreen {
        height: 2rem;
        width: 2rem;
        fill: var(--backgroundColor);
        background: var(--accentShade4);
        transition: fill 0.5s ease-in-out;

        &:hover {
            cursor: pointer;
            fill: var(--foregroundColor);
        }
    }

    &--green {
        height: 3.7rem;
        width: 3.7rem;
        transition: fill 0.5s ease-in-out;
        fill: var(--accent2Color);
    }
}
