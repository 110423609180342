.form{

    &:hover {
        background-color: var(--color-grey-light-3);
    }

    &--selected {
        background-color: var(--color-white);
    }


    &__radio-input{
        display: none;
    }

    &__group:not(:last-child){
        margin-bottom: 2rem ;
    }

    &__input{
        font-family: inherit;
        font-size: 1.5rem;
        color : inherit;
        padding: 1.5rem 2rem;
        border-radius: 2px;
        background-color: rgba(var(--color-white), .5);
        border: none;
        border-bottom: 3px solid transparent;
        width: 90%;
        display: block;
        transition: all 0.3s;

        &:focus{
            outline : none ;
            box-shadow: 0 1rem 2rem rgba( var(--color-white), .1);
            border-bottom: 3px solid var(--color-white);
        }
    
        &:focus:invalid{
            border-bottom: 3px solid var(--color-white);    
        }

        &::-webkit-input-placeholder{
            color : var(--color-white)
        }

    }

    &__label{
        font-size: 1.2rem;
        font-weight: 700;
        margin-left: 2rem;
        margin-top: .7rem;
        display: block;
        transition: all .3s;
    }

    &__input:placeholder-shown + &__label{
        opacity: 0;
        visibility: hidden;
        transform: translateY(-4rem);
    }

    &__radio_group {
        width: 50%;
        display: inline-block;
    }

    &__radio-label {
        font-size: $default-font-size;
        cursor: pointer;
        position: relative;
        padding-left: 4rem;
    }

    &__radio-button{
        height: 3rem;
        width: 3rem;
        border: 5px solid var(--color-white);
        border-radius: 50%;
        display: inline-block;
        position: absolute;
        left: 0;
        top:-0.4rem;

        &::after{
            content: "";
            display: block;
            height: 1.3rem;
            width: 1.3rem;
            border-radius: 50%;
            position: absolute;
            top:50%;
            left: 50%;
            transform: translate(-50% , -50%);
            background-color: var(--color-white);
            opacity : 0;
            transition: opacity 0.3s;
            
        }

    }

    &__radio-input:checked ~ &__radio-label &__radio-button::after{
         opacity: 1;   
    }

}