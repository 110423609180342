/* width */
::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
