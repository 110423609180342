.user {
    order: 0;

    @include respond(tab-land) {
        order: 1;
        width: 100%;
    }

    margin: 0.5rem;
    background-color: var(--backgroundColor);
    border-radius: var(--borderRadius);
    border: 2px solid var(--primaryShade5);

    display: flex;
    flex-direction: column;

    &__antibot {
        padding: 1rem;
        margin: 0.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 2px dotted var(--primaryShade5);
    }

    &__language {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        justify-items: center;
        align-content: center;
        padding: 1rem;

        &__lg {
            font-size: 1.3rem;
        }

        &__radio {
            position: relative;
            display: grid;
            grid-template-columns: 1fr 1fr;

            &--input {
                display: none;
                &:checked + label:after {
                    transform: scale(1);
                }
            }
            &--label {
                cursor: pointer;
                transform: translateY(-0.4rem);
                &:before {
                    content: "";
                    position: absolute;
                    width: 2.4rem;
                    height: 2.4rem;
                    border: 0.1rem solid var(--grayColor);
                    border-radius: 50%;
                    left: 0;
                    top: 0;
                }
                &:after {
                    content: "";
                    position: absolute;
                    width: 1.6rem;
                    height: 1.6rem;
                    top: 0.4rem;
                    left: 0.4rem;
                    background: var(--accent2Color);
                    border-radius: 50%;
                    transform: scale(0);
                    transition: 0.3s ease;
                }
            }
        }
    }

    &__login {
        display: flex;
        justify-content: center;
        flex-direction: column;
        font-size: 1.6rem;

        &__form {
            margin: 0.3rem;
            display: flex;
            flex-direction: column;
            padding: 1rem;
            border-radius: var(--borderRadius);

            &--login {
                border: 2px solid var(--primaryShade5);
            }
            &--register {
                background-color: var(--primaryShade1);
                border: 2px solid var(--primaryShade5);
            }
        }

        &__cb {
            margin-top: 1rem;
            margin-bottom: 1rem;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
        }

        &__antibot {
            margin: 0.3rem;
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        &__input {
            margin-top: 0.2rem;
            margin-bottom: 0.2rem;

            font-size: 1.6rem;
            padding: 1rem;
            width: 100%;
            font-family: inherit;
            flex: 1;
            background-color: var(--backgroundColor);
            color: var(--color-grey-dark-1);
            border: 2px solid var(--primaryShade5);

            &:focus {
                outline: none;
                border: 2px solid var(--accent2Color);
            }

            &:focus:invalid {
                border-bottom: 1px solid var(--color-white);
            }

            &::-webkit-input-placeholder {
                color: var(--color-white);
            }

            &:read-only {
                color: var(--neutralShade6);
            }
        }

        &__inputfac {
            margin: 0.2rem;
            font-size: 1.6rem;
            padding: 0.5rem;
            width: 5rem;
            font-family: inherit;
            background-color: var(--backgroundColor);
            color: var(--color-grey-dark-1);
            border: 2px solid var(--primaryShade5);

            &:focus {
                outline: none;
                border: 2px solid var(--accent2Color);
            }

            &:focus:invalid {
                border-bottom: 1px solid var(--color-white);
            }

            &::-webkit-input-placeholder {
                color: var(--color-white);
            }

            &:read-only {
                color: var(--neutralShade6);
            }
        }
    }

    &__info {
        font-size: 1.2rem;
    }

    &__fac {
        font-size: 1.5rem;
    }
}

.paiement_card_container {
    display: grid;
    row-gap: 1rem;
    grid-template-areas:
        "sectigo sectigo"
        "stripe stripe"
        "info info"
        "promo promo"
        "number number"
        "date cvc";

    &__sectigo {
        font-style: italic;
        margin-top: 1rem;
        grid-area: sectigo;
    }

    &__stripe {
        font-style: italic;
        grid-area: stripe;
    }

    &__promoCode {
        grid-area: promo;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    &__promo {
        width: 100%;
        margin-left: 1rem;
        border: 0.1rem solid #fff;
        border-radius: 0.5rem;
        padding: 0.7rem 0.5rem;
        background-color: #fff;
        font-size: 1.6rem;
        font-weight: bold;
        color: var(--foregroundColor);

        &:focus {
            outline: none;
        }


    }

    &__cardnumber {
        grid-area: number;
    }

    &__expiredate {
        grid-area: date;
    }

    &__cvc {
        grid-area: cvc;
    }

    &__info {
        font-style: italic;
        grid-area: info;
        margin-bottom: 2rem;
    }
}

.user__logout__button {
    outline: none;
    text-align: center;
    display: inline-block;
    align-self: center;
    border: none;
    margin-top: 1.5rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    margin-bottom: 1rem;

    font-size: 1.4rem;
    font-weight: bold;

    padding: 1rem;
    cursor: pointer;
    border-radius: var(--borderRadius);
    background: var(--primaryShade5);
    color: var(--backgroundColor);
    position: relative;
    top: 0;
    transition: 0.2s ease;
    min-width: 12rem;
    &:hover,
    &.hover {
        top: -3px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    }
}

.popup__paiementboth {
    border-radius: var(--borderRadius);
    border: 2px solid var(--primaryShade5);
    background: var(--backgroundColor);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: scale(0);
    animation: scale-in 0.2s ease-out forwards, expand 0.2s ease-out forwards;
}

.popup__paiement {
    position: fixed;
    background: #ffffff30;
    width: 100%;
    //min-width: 80rem;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 2000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4rem;

    &__container {
        min-width: 32rem;
        font-weight: bold;
        border-radius: var(--borderRadius);
        border: 2px solid var(--primaryShade5);
        background: var(--backgroundColor);
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__container__stripe {
        min-width: 32rem;
        font-weight: bold;
        border-radius: var(--borderRadius);
        border: 2px solid var(--primaryShade5);
        background: var(--backgroundColor);
        display: grid;
        grid-template-areas:
            "info info"
            "msg msg"
            "confirm confirm";
    }

    &__paypal {
        padding-top: 2rem;
        padding-bottom: 2rem;
        border-bottom: 2px solid var(--primaryShade5);
        border-top: 2px solid var(--primaryShade5);
        margin-top: 2rem;
        width: 60%;
    }

    &__notification {
        padding: 1.5rem;
        grid-area: info;
        background: var(--primaryShade5);
        font-size: 1.6rem;

        color: var(--backgroundColor);
    }

    &__msg {
        font-size: 1.6rem;
        grid-area: msg;
        text-align: center;
        margin: 1rem;
    }

    &__button {
        outline: none;
        text-align: center;
        display: inline-block;
        border: none;
        margin: 2rem;
        font-size: 1.6rem;
        font-weight: bold;

        padding: 1rem;
        cursor: pointer;
        border-radius: var(--borderRadius);
        background: var(--primaryShade5);
        color: var(--backgroundColor);
        position: relative;
        top: 0;
        transition: 0.2s ease;
        &:hover,
        &.hover {
            top: -3px;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
        }
    }
}

.num-margin-bottom {
    margin-bottom: 0.5rem;
}
