:root {

    --shadow-dark: 1px 2px 5px rgba(0,0,0,0.5);
    --shadow-medium: 0 2rem 5rem rgba(0,0,0,.1);
    --shadow-light: 0 2rem 5rem rgba(0,0,0,.06);

    --line: 1px solid var(--color-grey-light-2);

    --backgroundColor: #fff;
    --foregroundColor: #111;
    --primaryColor: #03a9f4;
    --primaryShade1: #e1f5fe;
    --primaryShade2: #b3e5fc;
    --primaryShade3: #4fc3f7;
    --primaryShade4: #0288d1;
    --primaryShade5: #0277bd;
    --secondaryColor: #673ab7;
    --secondaryShade1: #ede7f6;
    --secondaryShade2: #d1c4e9;
    --secondaryShade3: #9575cd;
    --secondaryShade4: #512da8;
    --secondaryShade5: #311b92;
    --accentColor: #009688;
    --accentShade1: #e0f2f1;
    --accentShade2: #b2dfdb;
    --accentShade3: #4db6ac;
    --accentShade4: #00796b;
    --accentShade5: #004d40;
    --accent2Color: #8bc34a;
    --accent2Shade1: #e7f6d5;
    --accent2Shade2: #c5e1a5;
    --accent2Shade3: #aed581;
    --accent2Shade4: #689f38;
    --accent2Shade5: #558b2f;
    --accent3Color: #f44336;
    --accent3Shade1: #ffdde0;
    --accent3Shade2: #ffcdd2;
    --accent3Shade3: #ef9a9a;
    --accent3Shade4: #d32f2f;
    --accent3Shade5: #b71c1c;


    --neutralShade1: #f2f2f2;
	--neutralShade2: #e8e9e9;
	--neutralShade3: #d1d3d4;
	--neutralShade4: #babdbf;
	--neutralShade5: #808488;
	--neutralShade6: #666a6d;
	--neutralShade7: #4d5052;
	--neutralShade8: #212122;

	--grayColor: #999;
	--lightGrayColor: #ddd;
    --verylightGrayColor:rgba(213, 204, 204, 0.2);;
    

	--borderRadius: 6px;
	--boxShadow: 0 2px 5px rgba(#333, 0.1);
	
	--canvasColor: #f9f9f9;
    --canvasColor5: #ffff80;
    

}

*, 
*::after, 
*::before {
    margin : 0;
    padding : 0;  
    box-sizing: inherit;
}

html{
    box-sizing: border-box;
    //this defines was 1rem is
    font-size: 62.5%; //1rem = 10px , 10px/16px = 62.5%

    
   /* @include respond(big-desktop) { // width < 1200?
        font-size: 100%; //1rem = 10px , 10px/16px = 62.5%
    }*/
/*
    @include respond(tab-land) { // width < 1200?
        font-size: 50%; //1rem = 10px , 10px/16px = 62.5%
    }*/

}

body {
    font-family: 'Roboto' ,'Open Sans' , sans-serif;
    line-height: 1.6rem;    
}

.material-icons.green { color: var(--accent2Color) }

.material-icons.blue { color: var(--primaryShade4) }