.mail {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin: 0.5rem;
    background-color: var(--primaryShade5);
    border-radius: var(--borderRadius);
    border: 2px solid var(--primaryShade5);
    color: var(--neutralShade7);
    width: 100%;
    margin: 0.5rem;

    @include respond(tab-land) {
        max-height: 78vh;
        overflow-y: auto;
    }

    &__container {
        padding: 2rem;
        background-color: var(--backgroundColor);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 100%;

        @include respond(tab-wideport) {
            width: 90%;
        }

        @include respond(tab-land) {
            width: 80%;
        }

        @include respond(tab-large) {
            width: 70%;
        }
    }

    &__form {
        width: 100%;
        height: 100%;
        
        margin: 0.5rem;
        display: flex;
        flex-direction: column;
    }

    &__input {
        margin-top: 0.2rem;
        font-size: 1.6rem;
        padding: 1rem;
        width: 100%;
        font-family: inherit;
        background-color: var(--backgroundColor);
        color: var(--color-grey-dark-1);
        border: 2px solid var(--primaryShade5);

        &:focus {
            outline: none;
            border: 2px solid var(--accent2Color);
        }

        &:focus:invalid {
            border-bottom: 1px solid var(--color-white);
        }

        &::-webkit-input-placeholder {
            color: var(--color-white);
        }

        &:read-only {
            color: var(--neutralShade6);
        }
    }

    &__textarea {
        margin-top: 0.2rem;
        font-size: 1.6rem;
        padding: 1rem;
        width: 100%;
        font-family: inherit;
        background-color: var(--backgroundColor);
        color: var(--color-grey-dark-1);
        border: 2px solid var(--primaryShade5);
        overflow: auto;
        resize: vertical;
        flex: 1;

        &:focus {
            outline: none;
            border: 2px solid var(--accent2Color);
        }

        &:focus:invalid {
            border-bottom: 1px solid var(--color-white);
        }

        &::-webkit-input-placeholder {
            color: var(--color-white);
        }

        &:read-only {
            color: var(--neutralShade6);
        }
    }

    &__info {
        margin-top: 1rem;
        margin-bottom: 0.5rem;
        font-size: 1.6rem;
        font-weight: bold;
    }
}

.mail__button {
    outline: none;
    text-align: center;
    display: inline-block;
    align-self: center;
    border: none;
    margin: 2rem;
    font-size: 1.6rem;
    font-weight: bold;

    padding: 1rem;
    cursor: pointer;
    border-radius: var(--borderRadius);
    background: var(--primaryShade5);
    color: var(--backgroundColor);
    position: relative;
    top: 0;
    transition: 0.2s ease;
    &:hover,
    &.hover {
        top: -3px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    }
}
