.mots {

    margin: 0.5rem;

    background-color: var(--backgroundColor);
    border-radius: var(--borderRadius);
    border: 2px solid var(--primaryShade5);

    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;

    @include respond(tab-land) {
        width: 100%;
    }

    &__list{
        list-style: none;  
        max-height: 70vh;
        overflow-y: auto;  
    }

    &__item{
        margin: 1rem ;
    }

    &__menu {
        background-color: var(--primaryShade5);
        padding: 1rem;
        font-size: 1.6rem;
        letter-spacing: 0.1rem;

        display: flex;
        justify-content: space-evenly;
        align-items: center;

        color : var(--backgroundColor);

        & span:last-of-type {
            margin-left: 1rem;
        }
        & button:last-child {
            margin-left: auto;
        }

        & button:focus {
            top: -3px;
        }
    }

}

.mot {

    display: grid;
    grid-template-columns: 1fr 10fr;
    justify-content: center;

    -webkit-animation: fadein 0.5s linear forwards;
    animation: fadein 0.5s linear forwards;

    &__radio {
    
        transform: translateY(25%);
    
        &--input {
            display: none;
            &:checked + label:after {
                transform: scale(1);
            }
        }
        &--label {
            position: relative;
            cursor: pointer;
    
            &:before {
                content: "";
                position: absolute;
                width: 2.4rem;
                height: 2.4rem;
                border: 0.1rem solid var(--grayColor);
                border-radius: 50%;
                left: 0;
                top: 0;
            }
            &:after {
                content: "";
                position: absolute;
                width: 1.6rem;
                height: 1.6rem;
                top: 0.4rem;
                left: 0.4rem;
                background: var(--secondaryColor);
                border-radius: 50%;
                transform: scale(0);
                transition: 0.3s ease;
            }
        }
    }

    &__form {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: var(--primaryShade5);
        border-radius: 3px;
        box-shadow: 0 0.2rem 0.2rem rgba($color-black, 0.5);

        &__name {
            font-size: 1.6rem;
            padding: 1.5rem;       
            color: var(--backgroundColor);
        }    
    }

    &__focus {
        background-color: var(--accent3Shade4);
    }
}


.mot-fadeout {

    border-radius: 3px;
    display: grid;
    grid-template-columns: 1fr 10fr;
    justify-content: center;

    -webkit-animation: fadeout 0.5s linear forwards;
    animation: fadeout 0.5s linear forwards;

    &__radio {
    
        transform: translateY(25%);
    
        &--input {
            display: none;
            &:checked + label:after {
                transform: scale(1);
            }
        }
        &--label {
            position: relative;
            cursor: pointer;
    
            &:before {
                content: "";
                position: absolute;
                width: 2.4rem;
                height: 2.4rem;
                border: 0.1rem solid var(--grayColor);
                border-radius: 50%;
                left: 0;
                top: 0;
            }
            &:after {
                content: "";
                position: absolute;
                width: 1.6rem;
                height: 1.6rem;
                top: 0.4rem;
                left: 0.4rem;
                background: var(--secondaryColor);
                border-radius: 50%;
                transform: scale(0);
                transition: 0.3s ease;
            }
        }
    }

    &__form {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: var(--primaryShade5);

        &__name {
            font-size: 1.6rem;
            padding: 1.5rem;       
            color: var(--backgroundColor);
        }    
    }

    &__focus {
        background-color: var(--accent3Shade4);
    }
}
