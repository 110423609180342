.subcriptionfree {
    order: 1;

    padding : 2.5rem;
    font-size: 1.6rem;
    line-height: 3rem;
    font-weight: bold;

    @include respond(tab-land) {
        width: 100%;
        order: 0;
        font-size: 1.3rem;
        line-height: 5rem;
        font-weight: bold;
    }

    @include respond(tab-large) {
        width: 100%;
        order: 0;
        font-size: 1.5rem;
        line-height: 5rem;
        font-weight: bold;
    }

    margin: 0.5rem;
    background-color: var(--backgroundColor);
    border-radius: var(--borderRadius);
    border: 2px solid var(--accent3Shade4);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    color: var(--neutralShade7);

    & h1 {
        width: 80%;
        text-align: center;
        font-size: 3rem;
        letter-spacing: 0.5rem;
        margin-bottom: 2rem;
        margin-top: 2rem;
        border-bottom: dotted;
        padding-bottom: 2rem;

        @include respond(tab-land) {
            font-size: 5rem;
            letter-spacing: 0.5rem;
            margin-bottom: 5rem;    
        }

    }

}

.subcriptionfull {
    order: 2;

    padding : 2.5rem;
    font-size: 1.6rem;
    line-height: 3rem;
    font-weight: bold;

    @include respond(tab-land) {
        width: 100%;
        font-size: 1.3rem;
        line-height: 5rem;
        font-weight: bold;
    }

    @include respond(tab-large) {
        width: 100%;
        font-size: 1.5rem;
        line-height: 5rem;
        font-weight: bold;
    }


    margin: 0.5rem;
    background-color: var(--backgroundColor);
    border-radius: var(--borderRadius);
    border: 2px solid var(--accent2Shade4);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    color: var(--neutralShade7);

    & h1 {
        width: 80%;
        text-align: center;
        font-size: 3rem;
        letter-spacing: 0.5rem;
        margin-bottom: 2rem;
        margin-top: 2rem;
        border-bottom: dotted;
        padding-bottom: 2rem;

        @include respond(tab-land) {
            font-size: 5rem;
            letter-spacing: 0.5rem;
            margin-bottom: 5rem;    
        }

    }

}