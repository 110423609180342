.popup__lecon {
    position: fixed;
    background: #ffffff30;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2999;
    display: flex;
    justify-content: center;
    align-items: center;

    &__form {
        
        border-radius: var(--borderRadius);
        border: 2px solid var(--neutralShade7);
        background: var(--neutralShade2);
        animation: scale-in 0.2s ease-out forwards, expand 0.2s ease-out forwards;
        transform: scale(0);
        width: 90%;
        max-height: 60vh;
        overflow-y: auto;  

        @include respond(tab-wideport) {
            width: 70%;
            max-height: 80vh;
        }

        @include respond(tab-land) {
            width: 50%;
            max-height: 100vh;
        }

    }

    &__container{
        background: var(--neutralShade2);
        display: grid;
        grid-template-columns:  1fr;
        padding: 2rem;
        
        & label:first-of-type {
            margin-top: 0rem;
          }

        &--button {
            display: grid;
            grid-template-columns:  1fr 1fr 1fr;
    
            margin-top: 1rem;
            background: var(--backgroundColor);    
        }
    }

    &__input {
        font-size: 1.6rem;
        padding : 1rem;
        background-color: #fff;
        border: none;
        width: 100%;
        
        &:read-only{
            background-color: #fff;
            color: var(--neutralShade5);
        }
    }

    &__textarea {
        font-size: 1.6rem;
        padding : 1rem;
        background-color: #fff;
        border: none;
        width: 100%;
        min-height: 20rem;
    }

    &__label {
        margin-top: 0.8rem;
        font-size: 1.5rem;
        padding : 1rem;
        border: none;
        width: 100%;
    }

    &__button{
        outline: none;
        text-align: center;
        display: inline-block;
        border: none;
        margin: 2rem;
        font-size: 1.6rem;
        font-weight: bold;
        padding: 1rem;
        cursor: pointer;
        border-radius: var(--borderRadius);
        background: var(--neutralShade2);
	    color: var(--neutralShade7);
        position: relative;
        top: 0;
        transition: 0.2s ease;
        &:hover,
        &.hover {
            top: -3px;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
        }
    }
}