.policy {
 
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;  
    align-items: center;  
    margin: 0.5rem;
    background-color: var(--primaryShade5);
    border-radius: var(--borderRadius);
    border: 2px solid var(--primaryShade5);
    color: var(--neutralShade7);   

    @include respond(tab-land) {
        max-height: 78vh;
        overflow-y: auto;    
    }

    &__container {
        padding: 2rem;
        background-color: var(--backgroundColor);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        width: 100%;

        @include respond(tab-wideport) {
            width: 90%;
        }

        @include respond(tab-land) {
            width: 80%;
        }

        @include respond(tab-large) {
            width: 70%;
        }
    }

    & a{
        text-decoration: none;
        cursor : pointer;

        &:link,  &:visited {
            color: var(--neutralShade7);
        }

        &:link:active,  &:visited:active {
            color: var(--neutralShade7);
        }
    }


    &__section {
        margin-top: 1rem;
    }

    & h1 {
        font-size: 1.6rem;
        margin-top: 1.8rem;
        margin-bottom: 1.8rem;

        @include respond(tab-port) {
            font-size: 1.6rem;
        }

        @include respond(tab-wideport) {
            font-size: 1.8rem;
        }

        @include respond(tab-land) {
            font-size: 2rem;
        }
    }

    & h2 {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        font-size: 1.4rem;

        @include respond(tab-port) {
            font-size: 1.5rem;
        }

        @include respond(tab-wideport) {
            font-size: 1.7rem;
        }

        @include respond(tab-land) {
            font-size: 1.9rem;
        }
    }

    & p , li {
        font-size: 1.4rem;
        line-height: 2rem;
        margin-left: 1rem;
        
        @include respond(tab-port) {
            font-size: 1.6rem;
        }

        @include respond(tab-wideport) {
            font-size: 1.7rem;
        }

        @include respond(tab-land) {
            font-size: 1.8rem;
        }
    }

}