.popup {
    position: fixed;
    background: #ffffff30;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 3000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;

    &__notification {
        border-radius: var(--borderRadius);
        border: 2px solid var(--neutralShade7);
        padding: 2rem;

        font-size: 1.6rem;
        font-weight: bold;

        display: flex;
        justify-content: space-between;
        align-items: center;

        height: 8rem;
        background: var(--neutralShade2);
        color: var(--neutralShade7);
        transform: scale(0);

        opacity: 0;
        overflow: hidden;
        animation: scale-in 0.2s ease-out forwards, expand 0.2s ease-out forwards;
        cursor: pointer;

        &__message {
            padding-right: 2.5rem;
        }

        &--error {
            border: 2px solid var(--accent3Shade5);
            background: var(--backgroundColor);
            color: var(--accent3Shade5);
            border-radius: var(--borderRadius);
            padding: 2rem;
            font-size: 1.6rem;
            font-weight: bold;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 8rem;
            transform: scale(0);
            opacity: 0;
            overflow: hidden;
            animation: scale-in 0.2s ease-out forwards, expand 0.2s ease-out forwards;
            cursor: pointer;
        }
    }
}

.popup__confirmation {
    position: fixed;
    background: #ffffff30;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 3000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;

    &__container {
        border-radius: var(--borderRadius);
        border: 2px solid var(--neutralShade7);
        background: var(--backgroundColor);
        display: grid;
        grid-template-areas:
            "info info"
            "cancel confirm";
        transform: scale(0);
        animation: scale-in 0.2s ease-out forwards, expand 0.2s ease-out forwards;
    }

    &__notification {
        padding: 3rem;
        grid-area: info;
        background: var(--neutralShade2);
        font-size: 1.6rem;

        color: var(--neutralShade7);
    }

    &__button {
        outline: none;
        text-align: center;
        display: inline-block;
        border: none;
        margin: 2rem;
        font-size: 1.6rem;
        font-weight: bold;

        padding: 1rem;
        cursor: pointer;
        border-radius: var(--borderRadius);
        background: var(--neutralShade2);
        color: var(--neutralShade7);
        position: relative;
        top: 0;
        transition: 0.2s ease;
        &:hover,
        &.hover {
            top: -3px;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
        }
    }
}

.popup__presentation {
    position: fixed;
    background: #ffffff30;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 3000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;

    &__container {
        border-radius: var(--borderRadius);
        border: 2px solid var(--neutralShade7);
        background: var(--backgroundColor);
        display: flex;
        flex-direction: column;
        transform: scale(0);
        animation: scale-in 0.2s ease-out forwards, expand 0.2s ease-out forwards;
    }

    &__notification {
        padding: 3rem;
        background: var(--neutralShade2);
        font-size: 1.6rem;
        //color: var(--neutralShade7);
    }

    &__presentation {
        align-self: center;
        color: var(--primaryShade4);

        &__content {
            display: flex;
            align-items: center;
            text-decoration: none;

            &:link, &:visited, &:hover, &:active{
                color: var(--foregroundColor);
            }

            &:hover{
                color: var(--primaryShade3);
                cursor: pointer;
            }


        }
    }

    &__action {
        display: flex;
    }

    &__button {
        width: 50%;
        outline: none;
        text-align: center;
        display: inline-block;
        border: none;
        margin: 2rem;
        font-size: 1.6rem;
        font-weight: bold;
        align-self: center;

        padding: 1rem;
        cursor: pointer;
        border-radius: var(--borderRadius);
        background: var(--primaryShade5);
        color: var(--backgroundColor);
        position: relative;
        top: 0;
        transition: 0.2s ease;
        &:hover,
        &.hover {
            top: -3px;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
        }
    }
}

.bg-video {
    width: 80%;
    height: 348px;
    align-self: center;
}
