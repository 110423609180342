.user-nav {
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    height: 10rem;

    flex: 1 1 auto;
    display: flex;
    justify-content: center;

    line-height: 1rem;    
    letter-spacing: 0.1rem;

    &__items:hover {
        background-color: var(--neutralShade7);
    }

    &__items {
        margin: 0.1rem;
        cursor: pointer;
        background-color: var(--primaryShade5);
        flex: 1 1 100%;
        font-size: 0.9rem;
        font-weight: bold;
        text-decoration: none;
        color: var(--backgroundColor);
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;

        //border: 1px solid var(--color-primary);
        border-radius: 3px;
        box-shadow: var(--shadow-light);
        //clip-path: polygon(0 0, 100% 0%, 100% 95%, 95% 100%, 0 100%);
        transition : transform 150ms ease-in, background-color 150ms ease-in;

        @include respond(tab-port) {
            font-size: 1.3rem;
        }
    
    }


    &__items--display {

        display: none;
        @include respond(tab-port) {
            display: flex;
            font-size: 1.3rem;
        }

        margin: 0.1rem;
        cursor: pointer;
        background-color: var(--primaryShade5);
        flex: 1 1 100%;
        font-size: 1rem;
        font-weight: bold;
        text-decoration: none;
        color: var(--backgroundColor);
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;

        //border: 1px solid var(--color-primary);
        border-radius: 3px;
        box-shadow: var(--shadow-light);
        //clip-path: polygon(0 0, 100% 0%, 100% 95%, 95% 100%, 0 100%);
        transition : transform 150ms ease-in, background-color 150ms ease-in;
    }


    &__items--selected {
        background-color: var(--neutralShade6);
        border: 1px solid var(--foregroundColor);
        box-shadow: var(--shadow-dark);
        -ms-transform: scale(1.04,1.04); /* IE 9 */
        transform: scale(1.04,1.04); /* Standard syntax */    
        clip-path: none;
        z-index: 50;

        & > .sep {
            transform: scaleX(1.5);
        }

        /*& ~ .user-nav__items {           
            clip-path: polygon(0 0, 100% 0%, 100% 100%, 5% 100%, 0 95%);
        }*/
    
    }

    &__logo {
        
        &--left {
            border-radius: 3px;
            margin-top: 0.1rem;
            margin-bottom: 0.1rem;    
            margin-right: 0.1rem;
            
            display : none;
            @include respond(tab-land) {
                display : block;
            }    
        }

        &--right {
            border-radius: 3px;
            margin-top: 0.1rem;
            margin-bottom: 0.1rem;    
            margin-left: 0.1rem;
            display : none;
            @include respond(tab-wideport) {
                display : block;
            }    
        }
    }
}

.sep {
    content: "";    
    height: 0.4rem;
    width : 50%;
    background-color: var(--backgroundColor);
    border-radius: 10px;
    transition: transform 150ms ease-in;
}