.presentation {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 0.5rem;
    width: 100%;
    background-color: var(--primaryShade5);
    border-radius: var(--borderRadius);
    border: 2px solid var(--primaryShade5);

    @include respond(tab-land) {
        max-height: 78vh;
        overflow-y: auto;
    }

    &__container {
        padding: 1rem;
        background-color: var(--backgroundColor);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        width: 100%;

        @include respond(tab-wideport) {
            width: 90%;
        }

        @include respond(tab-land) {
            width: 80%;
        }

        @include respond(tab-large) {
            width: 70%;
        }
    }

    & h1 {
        margin-top: 1.5rem;
        font-size: 1.7rem;
        align-self: center;
        text-align: center;

        @include respond(tab-port) {
            margin-top: 3rem;
            font-size: 3rem;
            line-height: 3rem;
        }

        @include respond(tab-wideport) {
            margin-right: 5rem;
            margin-left: 5rem;
            line-height: 3rem;
        }
    }

    & h2 {
        margin-top: 2rem;
        margin-right: 5rem;
        font-size: 2.5rem;
    }

    & h3 {
        margin-top: 2rem;
        font-size: 2rem;
    }

    & p {
        margin-top: 2rem;
        font-size: 1.6rem;
        line-height: 2.5rem;
        align-self: flex-start;

        @include respond(tab-wideport) {
            font-size: 1.7rem;
            margin-right: 5rem;
            margin-left: 5rem;
        }

        @include respond(tab-land) {
            font-size: 1.8rem;
            margin-right: 5rem;
            margin-left: 5rem;
        }

        @include respond(tab-large) {
            font-size: 1.9rem;
            margin-right: 5rem;
            margin-left: 5rem;
        }
    }

    &__encouragement {
        background-color: var(--primaryShade5);
        color: var(--backgroundColor);
        width: 80%;
        margin-top: 2rem;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: var(--borderRadius);
        box-shadow: 0 0.5rem 1.5rem rgba($color-black, 0.5);

        @include respond(tab-wideport) {
            width: 70%;
        }

        @include respond(tab-land) {
            width: 55%;
        }

        @include respond(tab-large) {
            width: 40%;
        }

        & p {
            text-align: center;
            font-size: 1.8rem;

            @include respond(tab-port) {
                font-size: 2rem;
            }
        }
    }

    &__step {
        margin-bottom: 1rem;
        width: 80%;
        padding: 1rem;

        &__item {
            font-size: 1.6rem;

            &:not(:last-child) {
                margin-bottom: 1rem;
            }
            @include respond(tab-wideport) {
                font-size: 1.7rem;
            }

            @include respond(tab-land) {
                font-size: 1.8rem;
            }

            @include respond(tab-large) {
                font-size: 1.9rem;
            }
        }
    }

    &__liendoc {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 80%;
        border-radius: var(--borderRadius);
        box-shadow: 0 0.5rem 1.5rem rgba($color-black, 0.5);
        margin-bottom: 2rem;

        @include respond(tab-wideport) {
            width: 70%;
        }

        @include respond(tab-land) {
            width: 55%;
        }

        @include respond(tab-large) {
            width: 40%;
        }

        & span {
            margin-top: 0rem;
        }

        & p {
            margin-top: 0.5rem;
            margin-right: 1rem;
            margin-bottom: 0rem;
        }
    }

    &__feature {
        margin-bottom: 2rem;
        width: 80%;
        padding: 3rem;
        border-radius: var(--borderRadius);
        box-shadow: 0 0.5rem 1.5rem rgba($color-black, 0.5);

        &__item {
            font-size: 1.5rem;
            font-weight: bold;

            &:not(:last-child) {
                margin-bottom: 1rem;
            }

            @include respond(tab-wideport) {
                font-size: 1.6rem;
            }

            @include respond(tab-land) {
                font-size: 1.7rem;
            }

            @include respond(tab-large) {
                font-size: 1.8rem;
            }
        }
    }

    &__cordialement {
        align-self: flex-end;
        margin-right: 5rem;
        margin-bottom: 2rem;
    }

    &__signature {
        align-self: flex-end;
        margin-bottom: 2rem;
        margin-right: 3rem;
        text-align: right;

        @include respond(tab-port) {
            margin-right: 5rem;
        }

        &__writing {
            width: 60%;
            @include respond(tab-port) {
                width: 70%;
            }
        }
    }

    &__exemple {
        width: 90%;

        @include respond(tab-wideport) {
            width: 80%;
        }

        @include respond(tab-land) {
            width: 65%;
        }

        @include respond(tab-large) {
            width: 55%;
        }
    }

    &__extitle {
        margin-top: 2rem;
        font-weight: bold;
        font-size: 2rem;

        @include respond(tab-port) {
            font-size: 2.5rem;
        }
    }

    &__bg-video {
        width: 80%;
        height: 348px;
        align-self: center;

        @include respond(tab-wideport) {
            width: 70%;
        }

        @include respond(tab-land) {
            width: 55%;
        }

        @include respond(tab-large) {
            width: 40%;
        }
    }

    &__japanese--width {
        margin : 2rem;
        width: 90%;
    }

    &__japanese--center {
        text-align: center;
    }

    &__japanese {
        width: 100%;
        background-color: var(--backgroundColor);
        display: grid;
        grid-template-columns: 1fr;
        justify-items: center;
        align-items: center;

        @include respond(tab-wideport) {
            grid-template-columns: 1fr 1fr;
        }

        @include respond(tab-land) {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }

    &__email {
        border-radius: 100px;
        box-shadow: 0 0.5rem 1.5rem rgba($color-black, 0.5);

    }
}

.story {
    margin-top: 2rem;

    &__shape {
        width: 15rem;
        height: 15rem;
        position: relative;
        border-radius: 50%;

        @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
            -webkit-clip-path: circle(50% at 50% 50%);
            clip-path: circle(50% at 50% 50%);
            -webkit-shape-outside: circle(50% at 50% 50%);
            shape-outside: circle(50% at 50% 50%);
            border-radius: none;
        }
    }

    &__img {
        height: 100%;
        transform: translateX(-2.5rem) translateY(7rem) scale(2);
        backface-visibility: hidden;
        transition: all 0.5s;
    }

    &__caption {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, 20%);
        color: var(--backgroundColor);
        text-transform: uppercase;
        font-size: 1.7rem;
        text-align: center;
        opacity: 0;
        transition: all 0.5s;
        backface-visibility: hidden;
    }

    &:hover &__caption {
        opacity: 1;
        transform: translate(-50%, -50%);
    }

    &:hover &__img {
        filter: blur(1px) brightness(90%);
    }
}

.presentationshort {
    max-width: 50rem;
    margin: 0.5rem;
    padding: 1rem;
    //    color: var(--neutralShade7);

    & h2 {
        margin-top: 2rem;
        margin-right: 5rem;
        font-size: 2.5rem;
    }

    & p {
        font-size: 1.6rem;
        line-height: 2.5rem;
    }

    & span {
        margin-top: 2rem;
    }
}

.composition {
    width: 100%;
    position: relative;
    min-height: 20rem;

    @include respond(tab-smallport) {
        width: 90%;
        min-height: 23rem;
    }

    @include respond(tab-port) {
        width: 82%;
        min-height: 30rem;
    }

    @include respond(tab-wideport) {
        width: 75%;
        min-height: 32rem;
    }

    @include respond(tab-land) {
        width: 70%;
        min-height: 35rem;
    }

    @include respond(tab-large) {
        width: 60%;
        min-height: 38rem;
    }

    &__photo {
        width: 50%;
        box-shadow: 0 1.5rem 2rem rgba($color-black, 0.5);
        border-radius: 2px;
        position: absolute;
        z-index: 10;
        transition: all 0.2s;
        outline-offset: 1rem;

        @include respond(tab-wideport) {
            width: 45%;
        }

        &--p1 {
            left: 1rem;
            top: 2rem;

            @include respond(tab-wideport) {
                left: 6rem;
                top: 2rem;
            }
        }

        &--p2 {
            right: 1rem;
            top: 4rem;

            @include respond(tab-wideport) {
                right: 6rem;
                top: 4rem;
            }
        }

        &--p3 {
            left: 20%;
            top: 10rem;

            @include respond(tab-wideport) {
                left: 25%;
                top: 10rem;
            }
        }

        &:hover {
            //outline: .5rem solid $color-primary;
            transform: scale(2) translateY(-0.5rem);
            box-shadow: 0 0.5rem 1rem rgba($color-black, 0.5);
            z-index: 20;

            @include respond(tab-wideport) {
                transform: scale(2.5) translateY(-0.5rem);
            }
        }
    }
}
